// import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from './Component/Navbarr';
import TextUtil from './Component/TextUtils';


function App() {
  return (
   <>
   <Navbar/>
   <TextUtil/>
   </>
   );
}

export default App;
