import React from 'react'
import { useState } from "react";
export default function TextUtils() {
    const [text, settext] = useState("");
    
    let style = {
        width: 100 +"%"
    }
    let ChangeText = (e) => {
        settext(e.target.value)
    }
    let UpperCaseFn = () => {
        settext(text.toUpperCase())
    }
    let LowerCaseFn = () => {
        settext(text.toLowerCase())
    }
    let ClearTextFn = () => {
        settext("")
    }
    let CopyTextFn = () => {
        if(text.split(" ").filter((e)=>{return e.length!==0}).length!==0)
        {
            let copy= document.getElementById("textutils");
            copy.select();
            navigator.clipboard.writeText(copy.value);
        }else
        {
            alert("Enter text first");
    }
    }
    const speak = () => {
        let msg = new SpeechSynthesisUtterance(text);
        window.speechSynthesis.speak(msg);
        const toogle = document.getElementById('toggle')
        if (toogle.textContent === "Speak") {
            toogle.innerHTML = "Stop"
        }
        else {
            toogle.innerHTML = "Speak"
            if (toogle.innerHTML === "Speak"){
                window.speechSynthesis.cancel();
            }
        }
    }
    const RemoveSpace= ()=>{
        let NewText=text.split(/[ ]+/);
        settext(NewText.join(" "));
    }
    // var word = text.split(" ").length;
    // if (word === 1) {
    //     word--;
    // }

    return (
        <div className='container text-center'>
            <h1 className='mx-2'>WelCome To TextUtils Center </h1>
            <h3 className='mx-2'>Put Your Text Below And use Buttons</h3>
            <div className="container">
                <span>{}</span><br />
                <textarea name="text" id="textutils" cols="7" rows="10" style={style} onChange={ChangeText} value={text} placeholder='Enter Your Text here'></textarea>
            </div>
            <button disabled={text.length===0} className="btn btn-outline-primary mx-2" onClick={UpperCaseFn}>To-UpperCase</button>
            <button disabled={text.length===0} className="btn btn-outline-primary mx-1" onClick={LowerCaseFn}>To-LowerCase</button>
            <button disabled={text.length===0} className="btn btn-outline-danger mx-2" onClick={ClearTextFn}>Clear</button>
            <button disabled={text.length===0} className="btn btn-outline-secondary mx-2" onClick={CopyTextFn}>Copy</button>
            <button disabled={text.length===0} className="btn btn-outline-warning mx-2" onClick={speak} id='toggle'>Speak</button>
            <button disabled={text.length===0} className="btn btn-outline-info mx-2" onClick={RemoveSpace}>Remove Extra Spaces</button>
            <div className="container">
                <h2 className='my-3'>Your Text Messurment</h2>
                <span>Number of character is:</span>
                <span className='mx-1'>{text.length}</span>
                <span className='mx-3'>Total Words {text.split(/\s+/).filter((e)=>{return e.length!==0}).length}</span>

                <span className="mx-1">Total time taken to finish this paragraph: {0.008 * text.split(" ").filter((e)=>{return e.length!==0}).length} Min.. </span>
                <h4>Preview of Your Text</h4>
                <p>{text.length>0?text:"Nothing to Preview"}</p>
            </div>

        </div>

    )
}
