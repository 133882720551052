import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import React from 'react'

export default function Navbarr() {
   let style={
    fontSize:30+"px",
    "cursor":"pointer"
   }
   
    const ChangeLMode = () => {
        document.querySelector('.body').style.backgroundColor = "auto"
      }
    
      const ChangeDMode = () => {
        document.querySelector('.body').style.backgroundColor = "black"
        document.querySelector('.body').style.color = "white"
        
      }

  return (
    <>

       <Navbar  expand="lg" className="bg-body-tertiary">
      <Container fluid>
        <Navbar.Brand href="#">TestUtils App</Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: '100px' }}
            navbarScroll
          >
            <Nav.Link href="#action1">Home</Nav.Link>
            <Nav.Link href="#action2">About</Nav.Link>
            <NavDropdown title="Information" id="navbarScrollingDropdown">
              <NavDropdown.Item href="#action3">Dev. Vishal</NavDropdown.Item>
              <NavDropdown.Item href="#action4">
                Create React App for learning
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action5">
                Course Compelete soon..
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="#" disabled>
              From NLCI
            </Nav.Link>
          </Nav>
          <Form className="d-flex">
              <i className='fa fa-sun my-1 mx-2'style={style} onClick={ChangeLMode}></i>
              <i className='fa fa-moon-o my-1 mx-2'style={style} onClick={ChangeDMode}></i>
            <Form.Control
              type="search"
              placeholder="Search"
              className="me-2"
              aria-label="Search"
            />
            <Button variant="outline-success">Search</Button>
          </Form>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </>
  )
}

